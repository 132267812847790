<template>
	<div id="cpm_liveList " class="cpm_liveList">
		<div class="list" v-if="info.list.length > 0">
			<div class="list-item" :title="item.name" v-for="(item,index) in info.list" :key="index">
				<div :id="'myPlayer'+index"></div>
			</div>
		</div>
		<div class="cpm_liveList-none" v-else>暂时没有监控信息</div>
		<div class="pager">
			<a-pagination
			    show-size-changer
			    :default-current="info.page"
			    :total="info.count"
			    @showSizeChange="(p,e)=>{getLiveList(info.page,e)}"
				@change="(e)=>{getLiveList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import HlsJsPlayer from 'xgplayer-hls.js';
import liveModel from '@/api/live.js'
import { reactive, toRefs } from 'vue';
import tool from '@/util/tool';
export default{
	name:'page-live-preview',
	props:{
		is_shop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})
		getLiveList(1,_d.info.limit)
		function getLiveList(page,limit){
			liveModel.getLiveList(page,limit,null,res=>{
				_d.info = {limit,...res}
				let list = _d.info.list;
				let timer = setTimeout(function(){
					clearTimeout(timer)
					for (var i = 0; i < list.length; i++) {
						_d.info.list[i].player = new HlsJsPlayer({
							id: 'myPlayer'+i,
							url: list[i].m3u8_src,
							fluid: true,
							poster:list[i].cover == "" ? tool.getStaticSrc("admin/common/live-cover.jpg"):list[i].cover,
							autoplay:false,
							error:"监控不在线"
						});
					}
				},1000)
			})
		}

		function clearPlayer(){
			for (var i = 0; i < _d.info.list.length; i++) {
				if( _d.info.list[i].player  ){
					_d.info.list[i].player.destroy(true)
				}
			}
		}
		return{
			...toRefs(_d),
			clearPlayer,
			getLiveList
		}
	},
}
</script>

<style lang="scss">
.cpm_liveList{
	background: #fff;
	.list{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.list-item{
		width: 33%;
	}
	
	&-none{
		padding: 24px;
		text-align: center;
		color: #999;
		font-size: 14px;
	}
}
</style>
